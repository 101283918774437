import React, {Component} from "react"
import {graphql, Link} from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Highlightable from "../../components/highlightable";
import ProductCell from "../../components/product/productCell"
import AnimatedLines from "../../components/animatedLines"
import { isBrowser } from "react-device-detect";

class GummiesPage extends Component {
  constructor(props) {
    super(props)
    const products = props.data.allWcProducts.edges;
    this.state = {
      products: products,
      filter: null,
      filteredProducts: products
    }
  }

  handleCategory = (event, category) => {
    event.preventDefault()
    if (category === this.state.filter) {
      this.setState({filter: null, filteredProducts: this.state.products})

    } else {
      const filtered = this.state.products.filter(function(e) {
        let names = e.node.categories.map((x) => {
          let name = x.name
          return name.toUpperCase()
        })
        return names.includes(category)
      });
      this.setState({filteredProducts: filtered, filter: category})
    }

  }
  render() {

    return (

      <Layout>
        <SEO title="Gummies"/>
        <section className="hero is-medium has-absolute-navbar">
          <div className="hero-body" style={{
            paddingBottom: "0px"
          }}>

            <div className="container">
              <div className="columns is-centered">
                <div className="column is-5 has-text-centered">
                  <h1>Gummies</h1>
                  <p>The first of its kind. Vegan multivitamin CBD gummies. Use our potent gummies made with a blend of 9 vitamins and minerals, for powerful, fast-acting effects - whether you’re looking to increase focus or calm the mind.</p>
                </div>

              </div>
            </div>
          </div>

        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-multiline is-centered is-mobile">

              {this.state.filteredProducts.map(({node}) => (<ProductCell product={node} key={node.id}/>))}
            </div>

          </div>

        </section>
        <section className="section is-medium" style={{
          position: "relative"
        }}>
          <AnimatedLines className="lines-01" style={{
            transform: "scaleX(-1) rotate(80deg)",
            position: "absolute",
            top: "50%",
            left: isBrowser ? "-10%" : "-95%",
            zIndex: "10",
            width: "587px"
          }}/>
          <AnimatedLines className="lines-02" style={{
            position: "absolute",
            transform: "scaleX(-1) rotate(-20deg)",
            top: "-80%",
            right: isBrowser ? "-8%" : "-95%",
            zIndex: "-1",
            width: "610px"
          }}/>
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-4-desktop is-6-tablet is-12-mobile has-text-centered">

                <h3 className="has-text-weight-bold">
                  <Highlightable>Caution</Highlightable>
                </h3>
                <h2>May experience feelings of bliss when taking these products</h2>

              </div>

            </div>
          </div>

        </section>
        <section className="section is-medium">

          <div className="container">
            <div className="columns is-vcentered">

              <div className="column is-relative happiness-inside-image" style={{
                backgroundImage: `url(https://reasontosmile.wpengine.com/wp-content/uploads/2020/01/WebsiteFinals-7.jpg)`
              }}/>
              <div className="column is-5 is-offset-1 has-text-centered-mobile">

                <h1 className="is-h1-desktop-mobile">
                  Happiness Inside
                </h1>
                <br/>
                <h4 className="">Smiles are contagious. Smiles have the power to change the world. Our CBD products are designed to help you tune into your best self, so you can be here & now… happily.</h4>
                <br/>
                <Link to="/story">

                  <button className="select-button is-primary">SEE OUR STORY</button>
                </Link>
              </div>

            </div>

          </div>

        </section>
      </Layout>
    )
  }
}

export default GummiesPage

// Set here the ID of the home page.
export const pageQuery = graphql `
  query {
    allWcProducts(filter: {catalog_visibility: {eq: "visible"}, status: {eq: "publish"}, categories: {elemMatch: {name: {regex: "/Gummies/i"}}}, tags: {elemMatch: {name: {eq: "CBD"}}}}) {
      edges {
        node {
          id
          type
          wordpress_id
          slug
          name
          price
          categories {
            wordpress_id
            name
          }
          acf {
            flavor
            effect
            product_type
          }
          images {
            id
            alt
            src
          }
          grouped_products_nodes {
            id
            wordpress_id
            type
            price
          }
        }
      }
    }
  }
`
